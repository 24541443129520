.main{
    height: 100vh;
    width: 100%;
    /* background-color: #D9D9D9; */

    color: #fff;
}

.header{
    background-color: #fff ;
    display: flex;
    align-items: center;
    padding: 7px 15px ;
    border-bottom: 1px solid rgba(134, 134, 134, 0.2);
    justify-content: center;
    z-index: 1000;  
}

.LogoHeader{
    display: flex;
    align-items: center;
}

.title{
    font-size: 20px;
    font-weight: bold;
    position: sticky;
    top: 0px;
    color: #000;
    
    margin-left: 15px;
}

.info__cargo{
    display: flex;
    align-items: center;
    justify-content: space-around;
    background-color: #fff ;
    background-color: #1f1d1d ;

}


.namecargo{
    font-size: 27px;
    font-weight: bold;
    background-color: #fff ;
    color: #fcfcfc;
    background-color: #1f1d1d ;
}

.logo2{
    /* width: 60px; */
    height: 50px;
    border-radius: 50%;
    margin: 5px 0;
}

.area{
    background-color: #D9D9D9;
    background-color: #e7e7e7;
    background-color: #F3F4F7;
}

/*  */




.section{
    padding: 20px 15px;
    background-color: #D9D9D9;
    background-color: #e7e7e7;
    background-color: #F3F4F7;

}

.text__block_info{
    background-color: #fff;
    color: #1A1A1A;
    font-weight: 400;
    font-size: 14px;
    text-align: center;
    margin-top: 6px;
}
.blocks__info{
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* 2 колонки */
    gap: 0px; /* промежуток между блоками */
    justify-items: center; /* Центрирует по горизонтали */
    align-items: center; /* Центрирует по вертикали */  
    
    background-color: #D9D9D9;
    background-color: #ffffff;

    /* border: 2px solid #B9B9B9; */
    box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.05);
    border-bottom: 1px solid rgba(134, 134, 134, 0.2);
    padding: 10px ;
    border-radius: 15px;
    margin:  0 0 20px 0 ;
}

.block_info{
    /* background-color: #ffffff; */
    
    padding: 0 10px;
    
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    
    border-radius: 13px;
    /* box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.02); */
    width: 100%; 
    height: 90px;
    /* border: 2px solid #B9B9B9; */

    transition-duration: 0.3s;
    cursor: pointer;
}
.block_info:hover{
    transform: scale(1.03);
    transition-duration: 0.3s;

}

.iconMain{
    height: 40px;
    background-color: #fff;
    margin-bottom: 3px;
}
/* about */

.chinaAddress{
    white-space: pre-wrap;
}

.about{
    margin-bottom: 30px;
    background-color: #ffffff;
    color: #000;
    padding: 30px 30px;
    border-radius: 10px;
    align-items: center;
    justify-content: center;
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.04);
    border: 2px solid #B9B9B9;
    border: 1px solid rgba(134, 134, 134, 0.2);
    white-space: pre-wrap;
}

.about h3{
    background-color: #ffffff;
    font-size: 20px;
    font-weight: 600;

}
.about p{
    background-color: #ffffff;
    margin: 20px 0 0 0;
    line-height: 18px;
    font-size: 16px;

}

.title2{
    font-size: 20px;
    font-weight: bold;
    padding: 20px 30px;
    position: sticky;
    top: 0px;
    color: #000;
    background-color: #fff;
    z-index: 1000;
}

.Menu{
    display: none;
}


@media only screen and (min-width: 1024px) {
    .blocks__info {
        grid-template-columns: repeat(4, 1fr); /* при ширине экрана больше 1000px будет 4 колонки */
        margin-top: 20px;
    }

    .header{
        display: flex;
        justify-content: space-around;
        padding: 10px 40px;
        

    }
    .Menu{
        display: flex;
        
    }
    .LogoHeader{
        display: flex;
        justify-content: center;
        /* margin-left: 20px; */
    }
    .tabbutton-menu{
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-right: 40px;
        background-color: rgba(228, 228, 228, 0.301);
        padding: 10px 15px;
        border-radius: 5px;
        transition-duration: 0.3s;
        border: 1px solid rgba(228, 228, 228, 0.4);
        
    }
    .tabbutton-menu:hover{
        background-color: #e7e7e7;
        box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.04);
        border: 1px solid rgb(185, 185, 185, 0.5);
    }
    .tabbutton-menu img{
        margin-right: 5px;
    }
    .abouts_container{
        display: flex;   
        margin-top: 40px;
    }
    .about{
        margin: 0 10px;

    }
}
  

.main-lost{
    flex: 1;
    display: flex;
    flex-direction: column;
    position: fixed;
    z-index: 10000;
    top: 1;
    height: 100vh;
    width: 100vw;

}
.section-lost{
    padding: 0 10px;
}

.filial-el-client{
    margin: 40px 0 10px 0;
}

.filial-el-client p{
    margin: 0;
}