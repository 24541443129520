/* background-color: #f7f7f7; */


.wrapper{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}


.logo{
    margin-top: 100px ;
    /* width: 100px; */
    height: 120px;
    border-radius: 10px;
    background-color:#fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    color: #fff;
}

.h1{
    font-size: 30px;
    margin-top: 40px;
    font-weight: 600;
    color: #000 ;

}

.buttons{
    margin-top: 50px;
    display: flex;
    flex-direction: column;
}
.button{
    margin-top: 15px;
    text-align: center;
    font-size: 16px;
    width: 300px;
    color: #fff;
    font-weight: 500;
    padding: 20px 0;
    border-radius: 16px;
    box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.2);
    transition-duration: 0.3s;
}
.auth__button{
    background-color: #5572f3; 
}
.reg__button{
    background-color: #5572f3;
}



.button:active{
    background-color: #00000077;

}

.button:hover{
    background-color: #00000077;

}