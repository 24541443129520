
.modal-overlay {
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000; /* Обеспечивает, что модальное окно находится поверх других элементов */
}
.modal-settings-header{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.modal-settings-header h2{
    font-weight: bold;
}

.close-modal{
    background-color: #fff;
}

.modal-content-settings {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    width: 90%;
    max-width: 600px;
    position: relative;
}


/* styles/changePassword.css */
.change-password-container {
    padding: 20px;
}

.form-group {
    margin-bottom: 15px;
}

.newPassLabel {
    display: block;
    margin-bottom: 5px;
}

.newPass{
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.error-message {
    color: red;
}

.success-message {
    color: green;
}

.submit-button {
    background-color: #4CAF50;
    color: white;
    padding: 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.close-button {
    margin-top: 10px;
    background-color: #f44336;
    color: white;
    padding: 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.language-switcher {
    margin-top: 20px;
    display: flex;
    gap: 10px; /* Отступ между кнопками */
}

.lang-button {
    border: 5px solid transparent; /* Цвет бордера по умолчанию */
    background-color: white; /* Цвет фона кнопки по умолчанию */
    padding: 5px; /* Паддинг */
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s, border-color 0.3s; /* Анимация при наведении */
    border-radius: 5px;
}

.lang-button:hover {
    background-color: lightgray; /* Цвет фона при наведении */
}

.lang-button.active {
    background-color: lightcoral; /* Цвет фона для активной кнопки */
    color: white; /* Цвет текста для активной кнопки */
}
